<template src="@/views/contactar/contactar-template.vue"></template>
<style src="@/views/contactar/contactar.css"></style>

<script>
import PortadaHeader from '@/components/global/PortadaHeader.vue';
import pqr from '@/components/entidad/peticionesQuejas/pqr/pqr.vue';
export default {
    name: 'Contactar',
    data: function() {
        return {
            msj: {
                titulo: 'Atencion al ciudadano',
                desc: 'Contacto',
                img: 'contactar.jpg'
            },
            datos: [{
                    icono: 'icon-location',
                    titulo: 'Dirección:',
                    desc: 'Calle 41 No. 31-17 Carretera Troncal del Caribe Variante Gaira-Mamatoco Santa Marta-Colombia'
                },
                {
                    icono: 'icon-phone',
                    titulo: 'Telefono:',
                    desc: '316 - 0104 538'
                },
                {
                    icono: 'icon-mail2',
                    titulo: 'Correo Institucional:',
                    desc: 'centraltsm@terminaldesantamarta.com'
                },
                {
                    icono: 'icon-mail2',
                    titulo: 'Correo notificaciones judiciales:',
                    desc: 'notificacionjudicial@terminaldesantamarta.com'
                },
                {
                    icono: 'icon-clock',
                    titulo: 'Horario de atención:',
                    desc: 'De lunes a viernes: 8 am - 12m | 2pm -6pm'
                },
            ]
        }
    },
    components: {
        PortadaHeader,
        pqr
    }
}
</script>
