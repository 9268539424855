<template>
    <div class="container-fluid">
        <PortadaHeader :msj="msj"></PortadaHeader>
        <div class="container">
            <div class="row mb-5">
                <div class="col-12">
                    <div class="titulo-destacado d-block mb-4">
                        <div class="titulos d-block mb-3">
                            <h3 class="font-weight-bold text-uppercase color-principal">Formulario de contacto</h3>
                            <h6 class="text-muted">Seleccion el tipo de contacto y llene los campos</h6>
                        </div>
                        <div class="decoracion">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 col-12 mb-lg-0 mb-5">
                    <div class="row">
                        <div class="col-12">
                            <pqr></pqr>
                        </div>
                    </div>
                </div>
                <div class="col-lg-7 col-12">
                    <iframe class="googlemap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3913.54199665845!2d-74.18307508558651!3d11.221483592020434!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8ef4f5afb8da4093%3A0x4bfa83f8bbebb7df!2sTerminal%20de%20Transportes%20de%20Santa%20Marta!5e0!3m2!1ses!2sco!4v1624465991067!5m2!1ses!2sco" width="600" height="100%" style="border:0; height: 500px;" allowfullscreen="" loading="lazy"></iframe>
                </div>
            </div>
            <div class="row">
                <div class="col-12 mb-3">
                    <div class="titulo-destacado d-block mb-4">
                        <div class="titulos d-block mb-3">
                            <h3 class="font-weight-bold text-uppercase color-principal">Atención al Usuario</h3>
                            <h6 class="text-muted">Estamos disponibles para todas sus inquietudes</h6>
                        </div>
                        <div class="decoracion">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="row px-3">
                        <div class="col-lg-6 col-12 mb-lg-3 mb-5" v-for="(item, index) of datos" v-bind:key="index">
                            <div class="row align-items-center">
                                <div class="col-icono tema-principal mr-3">
                                    <i :class="item.icono"></i>
                                </div>
                                <div class="col-md col-12 mt-2 mt-md-0 p-0">
                                    <h5 class="color-principal">{{ item.titulo }}</h5>
                                    <p class="mb-0">{{ item.desc }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>